<template>
  <div class="record">
    <nav-bar :title="$t('充值记录')" />

    <div class="tabs">
      <div class="tabs-item" :class="{ 'tabs-active': index == active }" v-for="(item, index) in tabs" :key="index"
        @click="tabsChange(index)">
        {{ item }}
      </div>
    </div>

    <div class="invest">
      <van-list v-if="list.length > 0" v-model:loading="loading" :finished="finished" :finished-text="$t('没有更多了')"
        :immediate-check="false" offset="10" @load="onLoad">
        <div class="invest-item" v-for="item in list" :key="item.id">
          <div v-if="active == 0">
            <div class="cell">
              <div>{{ item.cdate }}</div>
            </div>
            <div class="cell">
              <div>{{ $t('入金') }}：{{ item.jine }}</div>
              <div></div>
            </div>
            <div class="cell">
              <div>{{ $t('放大金额') }}：{{ item.amountTotal }}</div>
              <div>{{ $t('未释放') }}：{{ item.amount }}</div>
            </div>
            <div class="cell">
              <div>{{ $t('放大倍数') }}：{{ item.bei }}</div>
              <div>{{ $t('获得金额') }}：{{ item.shouyi }}</div>
            </div>
          </div>
          <!-- stc -->
          <div v-if="active == 1">
            <div class="cell">
              <div>{{ $t('时间') }}：{{ item.dateCreate }}</div>
              <div>{{ $t('金额') }}：{{ item.amount }}</div>
            </div>
            <div class="cell">
              <div>{{ $t('交易哈希') }}：</div>
              <div>
                {{ item.hash ? abbr(item.hash, 4, 4) : '-' }}
                <q-icon name="content_copy" style="font-size: 16px;" color="white" @click="copy(item.hash)" />
              </div>
            </div>
          </div>
        </div>
      </van-list>

      <empty v-if="list.length == 0"></empty>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { List } from "vant";
import CustomComponent from "@/components/CustomComponent";
import useClipboard from 'vue-clipboard3';

export default {
  name: '',
  components: {
    [List.name]: List,
  },
  setup() {
    return {
      us: ref(null),
      list: ref([]),
      pageIndex: ref(1),
      loading: ref(false),
      finished: ref(false),

      lx: ref(""),
      title: ref("充值记录"),

      active: ref(0),
      tabs: ref(['POL', 'STC']),
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    if (this.$route.query.lx) {
      this.lx = this.$route.query.lx
    }
    if (this.$route.query.title) {
      this.title = this.$route.query.title
    }
    // console.log(this.us)
    if (this.us) {
      this.getdata()
    }
  },
  methods: {
    onLoad() {
      this.loading = true
      this.pageIndex++
      if(this.active == 0) {
        this.getdata()
      } else {
        this.getStcData()
      }
    },
    copy(val) {
      let that = this
      const { toClipboard } = useClipboard()
      try {
        toClipboard(val)
        that.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ['Replicating Success'],
            persistent: true,
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    tabsChange(index) {
      this.active = index
      this.pageIndex = 1
      this.finished = false
      this.list = []
      if (index == 0) {
        this.getdata()
      } else {
        this.getStcData()
      }
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/Touzi/List",
        {
          userid: _this.us.userid,
          pageIndex: _this.pageIndex,
          pageSize: 10,
          state: ""
        },
        (res) => {
          _this.loading = false
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
          let data = res.data.data
          if (data.length == 0) {
            _this.finished = true
          } else {
            _this.finished = false
          }
          _this.list.push(...data)
          // console.log(data)
        }
      )
    },
    getStcData() {
      let _this = this
      _this.$request.post(
        "api/BscTransactions/List",
        {
          userid: _this.us.userid,
          pageIndex: _this.pageIndex,
          pageSize: 10
        },
        (res) => {
          _this.loading = false
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
          let data = res.data.data
          if (data.length == 0) {
            _this.finished = true
          } else {
            _this.finished = false
          }
          _this.list.push(...data)
          // console.log(data)
        }
      )
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
  }
}
</script>

<style scoped>
.record {
  padding: 10px;
}

.invest-item {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  background-color: #121C2E;
}

.cell {
  padding: 2px 0;
  display: flex;
  justify-content: space-between;
}

.cell-btn {
  display: flex;
  justify-content: flex-end;
}

.btn {
  margin-top: 8px;
  padding: 3px 15px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  background: linear-gradient(90deg, #f56ef9 0, #8a6dff);
  border-radius: 6px;
}

.tabs {
  margin-bottom: 15px;
  padding: 0 5px;
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 99px;
  background-color: #121C2E;
}

.tabs-item {
  flex: 1;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #4E5F7E;
  font-weight: bold;
  border-radius: 99px;
}

.tabs-active {
  color: #fff;
  background: linear-gradient(180deg, #884DDA 0%, #482974 100%);
}
</style>